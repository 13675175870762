import { INCOMING_MSG_AUDIO_NOTIFICATION } from "./constants";

export const AUDIO = new Audio(INCOMING_MSG_AUDIO_NOTIFICATION);

/**
 * This function plays a sound when a message is received from the customer
 *
 * @param event connect.ChatMessageEvent
 */
export function playSound(event: connect.ChatMessageEvent): void {
  if (isCustomerMessage(event)) {
    // play a sound
    void AUDIO.play();
  }
}

// Helper to determine if the message is a system message
function isCustomerMessage(event: connect.ChatMessageEvent): boolean {
  const { Type, ParticipantRole } = event.data;
  if (Type === "MESSAGE" && ParticipantRole === "CUSTOMER") {
    return true;
  }
  return false;
}
